import {
  Asset,
  Playlist,
  PlaylistSchedule,
  ScheduleDays,
  ScheduleRule,
} from '@designage/gql';
import { create, enforce, test } from 'vest';
import 'vest/enforce/date';
import moment from 'moment';

export const playlistValidator = create((model: Playlist) => {
  // Name validation
  test('name', 'Playlist name is required', () => {
    enforce(model.name).isNotEmpty();
  });

  // Assets/Day Parts validation
  test('assets', 'Playlist has no Day Parts', () => {
    enforce(model.assets.length).greaterThan(0);
  });
  // Content in day part validation
  test('content', 'Day part has no content', () => {
    model.assets.forEach((asset: Asset) => {
      enforce(asset.content.length).greaterThan(0);
    });
  });

  // Duration validation
  test('duration', 'Duration cannot be 0 seconds', () => {
    model.assets.forEach((asset) => {
      asset.content.forEach((content) => {
        if ('duration' in content) {
          enforce(content.duration).greaterThan(0);
        }
      });
    });
  });

  // Campaign start date validation
  test('campaignStart', 'Campaign start date is invalid', () => {
    model.assets.forEach((asset) => {
      asset.content.forEach((content) => {
        if (content.campaignStart) {
          enforce(moment(content.campaignStart).isValid()).equals(true);
        }
      });
    });
  });

  // Campaign end date validation
  test('campaignEnd', 'Campaign end date is invalid', () => {
    model.assets.forEach((asset) => {
      asset.content.forEach((content) => {
        if (content.campaignEnd) {
          enforce(moment(content.campaignEnd).isValid()).equals(true);
        }
      });
    });
  });
});

export const playlistScheduleValidator = create(
  (schedule: PlaylistSchedule) => {
    // Date syntax validation
    test('startDate', 'Start date is invalid', () => {
      enforce(schedule.startDate).isISO8601();
    });
    test('endDate', 'End date is invalid', () => {
      enforce(schedule.endDate).isISO8601();
    });
    // Date order validation
    test('startDate', 'Start date is after end date', () => {
      enforce(schedule.startDate).isBefore(schedule.endDate);
    });
    test('endDate', 'End date is before start date', () => {
      enforce(schedule.endDate).isAfter(schedule.startDate);
    });
  },
);

export const playlistScheduleRuleValidator = create((rules: ScheduleRule[]) => {
  // Helper function to filter out __typename
  const filterTypename = (days: ScheduleDays): Record<string, boolean> => {
    const { __typename, ...filteredDays } = days;
    return filteredDays;
  };

  // Existing day validation
  test('days', 'At least one day must be selected', () => {
    const filteredDays = filterTypename(rules[0].days);
    enforce(Object.values(filteredDays).some((day) => day === true)).equals(
      true,
    );
  });

  // Time overlap validation
  test('timeRanges', 'Time ranges cannot overlap for the same days', () => {
    // Helper function to check if two time ranges overlap
    const doTimesOverlap = (
      start1: string,
      end1: string,
      start2: string,
      end2: string,
    ): boolean => {
      const t1 = moment(start1, 'HH:mm');
      const t2 = moment(end1, 'HH:mm');
      const t3 = moment(start2, 'HH:mm');
      const t4 = moment(end2, 'HH:mm');

      return t1.isBefore(t4) && t2.isAfter(t3);
    };

    // Helper function to check if two rules share any days
    const shareCommonDays = (
      days1: Record<string, boolean>,
      days2: Record<string, boolean>,
    ): boolean => {
      return Object.entries(days1).some(
        ([day, isSelected]) => isSelected && days2[day],
      );
    };

    // Check each rule against every other rule
    for (let i = 0; i < rules.length; i++) {
      for (let j = i + 1; j < rules.length; j++) {
        const rule1 = rules[i];
        const rule2 = rules[j];
        const filteredDays1 = filterTypename(rule1.days);
        const filteredDays2 = filterTypename(rule2.days);

        // Only check for overlap if the rules share common days
        if (shareCommonDays(filteredDays1, filteredDays2)) {
          const hasOverlap = doTimesOverlap(
            rule1.startTime,
            rule1.endTime,
            rule2.startTime,
            rule2.endTime,
          );

          enforce(hasOverlap).equals(false);
        }
      }
    }
  });
});
