import {
  signalStore,
  withState,
  withComputed,
  withMethods,
  patchState,
  withHooks,
} from '@ngrx/signals';
import {
  MediaService,
  PlaylistService,
  ToasterService,
} from '@desquare/services';
import { computed, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { GraphQLFormattedError } from 'graphql';
import {
  AssetItem,
  ChannelPlaylist,
  ChannelRegionInput,
  Maybe,
  MediaDetailedFragment,
  Playlist,
  PlaylistStatus,
  PlaylistType,
  ResizeCropMethod,
  SaveOption,
  ScheduleDays,
  ScheduleRule,
  SmilRegion,
  TransitionEffect,
} from '@designage/gql';
import { LocalStorageService } from 'ngx-webstorage';
import { localStorageKeys } from '@desquare/constants';
import { cloneDeep, filter } from 'lodash';
import { arrayItemMove, playlistEngine } from '@desquare/utils';
import moment from 'moment';
import { playlistValidator } from '@desquare/validators';

export interface IPlaylistSignal {
  /** initial status of playlist from db, WITHOUT open/pinned statuses */
  originalPlaylist?: Playlist;
  playlist: Playlist;
  loading: boolean;
  loaderMessage: string;
  errors: GraphQLFormattedError<Record<string, any>>[] | null;
}

const initialState: IPlaylistSignal = {
  originalPlaylist: undefined,
  playlist: playlistEngine.playlist.createNew(),
  loading: false,
  loaderMessage: '',
  errors: null,
};

/** isOpen and isPinned should not matter in dirty status recognition */
function cleanRuntimeFlags(originalPlaylist: Maybe<Playlist>) {
  const p = cloneDeep(originalPlaylist);
  if (p) {
    p.assets.forEach((a) => {
      a.content.forEach((item) => {
        delete item.isOpen;
        delete item.isPinned;
        // item.isOpen = null;
        // item.isPinned = null;
      });
    });
  }
  return p;
}

export const PlaylistStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withMethods(
    (
      store,
      playlistService = inject(PlaylistService),
      mediaService = inject(MediaService),
      localStorageService = inject(LocalStorageService),
      toasterService = inject(ToasterService),
    ) => ({
      createNew: (name = 'New playlist', type = PlaylistType.Scheduled) => {
        localStorageService.store(localStorageKeys.ACTIVE_PLAYLIST_ID, '');
        const playlist = playlistEngine.playlist.createNew(name, type);
        patchState(store, {
          originalPlaylist: undefined,
          playlist,
          loading: false,
          errors: [],
        });
      },
      reset: (): Promise<boolean> => {
        localStorageService.store(localStorageKeys.ACTIVE_PLAYLIST_ID, '');
        patchState(store, {
          originalPlaylist: undefined,
          playlist: playlistEngine.playlist.createNew(),
          loading: false,
          loaderMessage: '',
          errors: null,
        });
        return Promise.resolve(true);
      },
      getPlaylistFromApi: async (id: string) => {
        return lastValueFrom(playlistService.getDetailedPlaylist$(id)).then(
          ({ playlist, loading, errors }) => {
            localStorageService.store(localStorageKeys.ACTIVE_PLAYLIST_ID, id);

            const p = cloneDeep(playlist);
            p.assets = playlistEngine.assets.sortDayParts(p.assets);

            for (const a of p.assets) {
              a.content = a.content.map((x) => {
                return {
                  ...x,
                  isOpen: x.isOpen === true,
                };
              });
            }

            patchState(store, {
              originalPlaylist: cloneDeep(p),
              playlist: p,
              loading,
              loaderMessage: 'FETCHING_PLAYLIST',
              errors: errors ? [...errors] : [],
            });
          },
        );
      },
      updateOriginalPlaylist: () => {
        const playlistId = store.playlist().id;
        const originalPlaylist = store.originalPlaylist?.();
        if (!playlistId || !originalPlaylist) return;
        lastValueFrom(playlistService.getDetailedPlaylist$(playlistId)).then(
          ({ playlist }) => {
            if (playlist.updatedAt !== originalPlaylist.updatedAt) {
              patchState(store, {
                originalPlaylist: cloneDeep(playlist),
              });
            }
          },
        );
      },
      revertTo: async (id: string) => {
        return lastValueFrom(playlistService.getDetailedPlaylist$(id)).then(
          ({ playlist, loading, errors }) => {
            const reverted = cloneDeep(playlist);
            reverted.id = store.playlist().id;
            reverted.channelRegions = store.playlist().channelRegions;

            patchState(store, {
              playlist: reverted,
              loading,
              // loaderMessage: 'FETCHING_PLAYLIST',
              errors: errors ? [...errors] : [],
            });
          },
        );
      },
      save: async () => {
        const playlist = playlistService.cleanupPlaylistFormValues(
          store.playlist(),
        );

        const { id, name, assets, channelRegions, type, schedule } = playlist;
        const { startDate, endDate, rules } = schedule || {};
        const channelIds: Maybe<ChannelRegionInput[]> = channelRegions?.map(
          (x) => {
            return { id: x.channelId, region: x.region };
          },
        );

        let savedPlaylist: Maybe<Playlist>;
        if (playlist.id && playlist.id !== '') {
          const ret = await playlistService.updatePlaylist({
            id,
            name,
            assets,
            channelIds,
            schedule: {
              startDate,
              endDate,
              rules,
            },
            saveOption: SaveOption.Update,
            type,
          });
          if (ret && ret.isSuccessful && ret.playlist) {
            savedPlaylist = ret.playlist as Playlist;
          }
        } else {
          const ret = await playlistService.createPlaylist({
            name,
            assets,
            channelIds,
            type,
            schedule: {
              startDate,
              endDate,
              rules,
            },
            profileId: '', // api will pick current profile
            saveOption: SaveOption.Draft,
          });
          if (ret && ret.isSuccessful) {
            savedPlaylist = ret.playlist as Playlist;
            savedPlaylist.status = PlaylistStatus.ReadyToPublish;
          }
        }
        if (savedPlaylist) {
          patchState(store, {
            originalPlaylist: cloneDeep(savedPlaylist),
            playlist: savedPlaylist,
          });
          return true;
        }
        return false;
      },
      publish: async (comment: string) => {
        const playlist = cloneDeep(store.playlist());
        const { id, name } = playlist;

        if (playlist.id && playlist.id !== '') {
          const ret = await playlistService.updatePlaylist({
            id,
            name,
            comment,
            assets: [],
            saveOption: SaveOption.Publish,
          });
          if (ret && ret.isSuccessful && ret.playlist) {
            const publishedPlaylist = ret.playlist as Playlist;
            publishedPlaylist.status = PlaylistStatus.Published;

            patchState(store, {
              playlist: publishedPlaylist,
              originalPlaylist: cloneDeep(publishedPlaylist),
            });
            playlistService.refreshPlaylistsFromApi();
            toasterService.success('PUBLISH_PLAYLIST_SUCCESS', playlist.name);
          }
        }
      },
      setPlaylistType: (type: PlaylistType) => {
        const playlist = cloneDeep(store.playlist());
        playlistEngine.playlist.setValue(playlist, 'type', type);
        patchState(store, {
          playlist,
        });
      },
      setPlaylistName: (name: string) => {
        const playlist = cloneDeep(store.playlist());
        playlistEngine.playlist.setValue(playlist, 'name', name);
        patchState(store, {
          playlist,
        });
      },
      setPlaylistStartEndDate: (
        field: 'startDate' | 'endDate',
        isoDateTime: string | null,
      ) => {
        const playlist = cloneDeep(store.playlist());
        playlistEngine.playlist.setScheduleValue(playlist, field, isoDateTime);

        patchState(store, {
          playlist,
        });
      },
      setScheduleRules: (rules: ScheduleRule[]) => {
        const playlist = cloneDeep(store.playlist());
        const newSchedule = { ...playlist.schedule, rules };
        playlistEngine.playlist.setValue(playlist, 'schedule', newSchedule);
        patchState(store, { playlist });
      },
      setChannelRegions: (channelRegions: ChannelPlaylist[]) => {
        const playlist = cloneDeep(store.playlist());
        playlistEngine.playlist.setValue(
          playlist,
          'channelRegions',
          channelRegions,
        );
        patchState(store, {
          playlist,
        });
      },

      /** Asset, DayPart, Sequence */
      setSequenceName: (assetIdx: number, name: string) => {
        const playlist = cloneDeep(store.playlist());
        // doable but needed?
        playlistEngine.assets.setValue(playlist.assets[assetIdx], 'name', name);
        patchState(store, { playlist });
      },
      /** add new day part */
      addSequence: () => {
        const playlist = cloneDeep(store.playlist());
        playlistEngine.assets.add(playlist);
        patchState(store, { playlist });
      },
      deleteSequence: (idx: number) => {
        const playlist = cloneDeep(store.playlist());
        playlistEngine.assets.delete(playlist, idx);
        if (playlist.assets.length === 0) {
          playlistEngine.assets.add(playlist);
          toasterService.info('PLAYLIST_CANNOT_BE_EMPTY');
        }
        patchState(store, { playlist });
      },
      setDayPartStartTime: (assetIdx: number, isoTime = '00:00:00') => {
        const playlist = cloneDeep(store.playlist());

        playlistEngine.assets.setStartTime(playlist, assetIdx, isoTime);
        patchState(store, { playlist });
      },
      /** MEDIA ITEMS */
      /** add item to a sequence or day part */
      addContentItem: (
        assetIdx: number,
        itemIdx: number = -1,
        item: AssetItem,
      ) => {
        const playlist = cloneDeep(store.playlist());
        playlistEngine.items.addAssetItem(playlist, assetIdx, itemIdx, item);
        patchState(store, { playlist });
      },
      addMedia: (
        assetIdx: number,
        itemIdx: number = -1,
        mediaWithMetadata: MediaDetailedFragment,
      ) => {
        const playlist = cloneDeep(store.playlist());
        playlistEngine.items.addMediaItem(
          playlist,
          assetIdx,
          itemIdx,
          mediaWithMetadata,
        );
        patchState(store, { playlist });
      },
      duplicateContentItem: (assetIdx: number, itemIdx: number) => {
        const playlist = cloneDeep(store.playlist());
        playlistEngine.items.duplicateItem(playlist, assetIdx, itemIdx);
        patchState(store, { playlist });
      },

      setContentItemOpen: (
        assetIdx: number,
        itemIdx: number,
        open?: boolean,
      ) => {
        const playlist = cloneDeep(store.playlist());
        const item = playlist.assets[assetIdx].content[itemIdx];
        const currentValue = item.isOpen === true;
        const newValue = open !== undefined ? open : !currentValue;

        if (newValue) {
          // close all others if not pinned
          for (const item of playlist.assets[assetIdx].content) {
            item.isOpen = item.isPinned === true;
          }
        }
        playlist.assets[assetIdx].content[itemIdx].isOpen = newValue;
        patchState(store, { playlist });
      },

      setContentItemPin: (assetIdx: number, itemIdx: number, pin?: boolean) => {
        const playlist = cloneDeep(store.playlist());
        const newPin = !!pin
          ? pin
          : !(playlist.assets[assetIdx].content[itemIdx].isPinned || false);
        playlist.assets[assetIdx].content[itemIdx].isPinned = newPin;
        patchState(store, { playlist });
      },
      deleteContentItem: (assetIdx: number, itemIdx: number) => {
        const playlist = cloneDeep(store.playlist());
        playlistEngine.items.deleteAssetItem(playlist, assetIdx, itemIdx);
        patchState(store, { playlist });
      },
      setContentItemContent: (
        assetIdx: number,
        itemIdx: number,
        newAssetItem: AssetItem,
      ) => {
        const playlist = cloneDeep(store.playlist());
        playlistEngine.items.replaceAssetItem(
          playlist,
          assetIdx,
          itemIdx,
          newAssetItem,
        );
        patchState(store, { playlist });
        // NOTE: this should be connected to setAssetItemMedia somehow
      },
      setContentItemMedia: (
        assetIdx: number,
        itemIdx: number,
        mediaWithMetadata: MediaDetailedFragment,
      ) => {
        const playlist = cloneDeep(store.playlist());
        // playlist.assets[assetIdx].content[itemIdx].days = days;
        playlistEngine.items.replaceMedia(
          playlist,
          assetIdx,
          itemIdx,
          mediaWithMetadata,
        );
        patchState(store, { playlist });
      },
      setContentItemName: (assetIdx: number, itemIdx: number, name: string) => {
        const playlist = cloneDeep(store.playlist());
        playlistEngine.items.setValue(
          playlist.assets[assetIdx].content[itemIdx],
          'name',
          name,
        );
        patchState(store, { playlist });
      },
      setContentItemDays: (
        assetIdx: number,
        itemIdx: number,
        days: ScheduleDays,
      ) => {
        const playlist = cloneDeep(store.playlist());
        playlistEngine.items.setItemDays(playlist, assetIdx, itemIdx, days);
        patchState(store, { playlist });
      },
      setContentItemDuration: (
        assetIdx: number,
        itemIdx: number,
        durationMs: number,
      ) => {
        const playlist = cloneDeep(store.playlist());
        // playlist.assets[assetIdx].content[itemIdx].days = days;
        playlistEngine.items.setValue(
          playlist.assets[assetIdx].content[itemIdx],
          'duration',
          durationMs,
        );
        patchState(store, { playlist });
      },
      setContentItemCampaignDate: (
        assetIdx: number,
        itemIdx: number,
        field: 'campaignStart' | 'campaignEnd',
        isoDateTime: string | null,
      ) => {
        const playlist = cloneDeep(store.playlist());

        playlistEngine.items.setValue(
          playlist.assets[assetIdx].content[itemIdx],
          field,
          isoDateTime,
        );
        patchState(store, {
          playlist,
        });
      },
      setContentItemResizeCrop: (
        assetIdx: number,
        itemIdx: number,
        resizeCropMethod: Maybe<ResizeCropMethod>,
      ) => {
        const playlist = cloneDeep(store.playlist());

        playlistEngine.items.setItemResizeCrop(
          playlist,
          assetIdx,
          itemIdx,
          resizeCropMethod,
        );

        patchState(store, {
          playlist,
        });
      },
      setContentItemTransitionEffect: (
        assetIdx: number,
        itemIdx: number,
        transitionEffect: Maybe<TransitionEffect>,
      ) => {
        const playlist = cloneDeep(store.playlist());

        playlistEngine.items.setItemTransitionEffect(
          playlist,
          assetIdx,
          itemIdx,
          transitionEffect,
        );

        patchState(store, {
          playlist,
        });
      },
      setContentItemDisabled: (
        assetIdx: number,
        itemIdx: number,
        disabled: boolean,
      ) => {
        const playlist = cloneDeep(store.playlist());

        playlistEngine.items.setValue(
          playlist.assets[assetIdx].content[itemIdx],
          'disabled',
          disabled,
        );
        patchState(store, {
          playlist,
        });
      },
      setContentItemDisabledOptimization: (
        assetIdx: number,
        itemIdx: number,
        disabled: boolean,
      ) => {
        const playlist = cloneDeep(store.playlist());

        playlistEngine.items.setItemDisabledOptimization(
          playlist,
          assetIdx,
          itemIdx,
          disabled,
        );

        patchState(store, {
          playlist,
        });
      },
      setItemPinned: (assetIdx: number, itemIdx: number, isPinned: boolean) => {
        const playlist = cloneDeep(store.playlist());

        playlistEngine.items.setValue(
          playlist.assets[assetIdx].content[itemIdx],
          'isPinned',
          isPinned,
        );
        patchState(store, {
          playlist,
        });
      },
      /** use undefined to toggle value */
      setItemPop: (
        assetIdx: number,
        itemIdx: number,
        enabled: boolean | 'toggle',
      ) => {
        const playlist = cloneDeep(store.playlist());

        playlistEngine.items.setItemPopEnabled(
          playlist,
          assetIdx,
          itemIdx,
          enabled,
        );
        patchState(store, {
          playlist,
        });
      },
      setItemPopTrackId: (assetIdx: number, itemIdx: number, id: string) => {
        const playlist = cloneDeep(store.playlist());

        playlistEngine.items.setItemPopTrackId(playlist, assetIdx, itemIdx, id);
        patchState(store, {
          playlist,
        });
      },
      moveAssetItem: (
        oldAssetIdx: number,
        oldItemIdx: number,
        newAssetIdx: number,
        newItemIdx: number,
      ) => {
        const playlist = cloneDeep(store.playlist());
        arrayItemMove(
          oldItemIdx,
          newItemIdx,
          playlist.assets[oldAssetIdx].content,
          newAssetIdx !== oldAssetIdx
            ? playlist.assets[newAssetIdx].content
            : undefined,
        );
        console.log(
          'playlist.assets[oldAssetIdx]',
          playlist.assets[oldAssetIdx].content.length,
        );
        patchState(store, {
          playlist,
        });
      },
      unpinAll: () => {
        const playlist = cloneDeep(store.playlist());
        playlist.assets.forEach((a) => {
          a.content.forEach((i) => {
            i.isPinned = false;
          });
        });
        patchState(store, {
          playlist,
        });
      },
      /** parse interactive regions, add target items to this sequence, remove target items not mapped in regions */
      setInteractiveItem: async (
        assetIdx: number,
        itemIdx: number,
        interactiveRegions: Maybe<SmilRegion>[],
        interactiveSource: string,
        width: number,
        height: number,
      ) => {
        const targetMediaIds = interactiveRegions
          .map((x) => x?.action?.target || '')
          .filter((x) => x !== '');
        const targetMedia = await mediaService.getMediaByIds(targetMediaIds);
        const playlist = cloneDeep(store.playlist());
        playlistEngine.items.setInteractiveItem(
          playlist,
          assetIdx,
          itemIdx,
          interactiveSource,
          interactiveRegions,
          width,
          height,
          targetMedia || [],
        );
        patchState(store, {
          playlist,
        });
      },
    }),
  ),
  withHooks((store) => ({
    onInit() {
      // do nothing for now
    },
  })),
  withComputed((state) => ({
    isAnythingPinned: computed(() => {
      const p = state.playlist();

      for (const a of p.assets) {
        for (const i of a.content) {
          if (i.isPinned) return true;
        }
      }

      return false;
    }),
    /** evaluate changes except open/pinned statuses */
    isDirty: computed(() => {
      const oldState = state.originalPlaylist
        ? JSON.stringify(cleanRuntimeFlags(state.originalPlaylist()))
        : '';
      const newState = state.playlist
        ? JSON.stringify(cleanRuntimeFlags(state.playlist()))
        : '';
      const isDirty = oldState !== newState;
      return isDirty;
    }),
    playlistCleaned: computed(() => {
      return cleanRuntimeFlags(state.playlist());
    }),
    isValid: computed(() => {
      const playlist = state.playlist(); // Assuming you have a signal or state for the playlist

      // Run the Vest validator
      return playlistValidator(playlist);
    }),
    playlistStartDate: computed(() => {
      const date = state.playlist().schedule?.startDate;
      console.log('playlistStartDate', date);
      if (!date) return '';
      return moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    }),
    playlistEndDate: computed(() => {
      const date = state.playlist().schedule?.endDate;
      console.log('playlistEndDate', date);
      if (!date) return '';
      return moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
    }),
    isDuplicateStartTime: computed((): boolean => {
      return (
        filter(
          state.playlist().assets,
          (x) =>
            filter(
              state.playlist().assets,
              (y) => y.actualStartTime === x.actualStartTime,
            ).length > 1,
        ).length > 0
      );
    }),
    isChanged: computed(() => {
      const originalPlaylist = state.originalPlaylist?.();
      if (!originalPlaylist) return false;
      const playlist = state.playlist();
      return playlist.updatedAt !== originalPlaylist.updatedAt;
    }),
  })),
);
