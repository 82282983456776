import {
  Asset,
  AssetItem,
  AssetType,
  ImageAsset,
  Maybe,
  Media,
  Playlist,
  PlaylistSchedule,
  PlaylistSource,
  PlaylistStatus,
  PlaylistType,
  ResizeCropMethod,
  ScheduleDays,
  SmilRegion,
  TransitionEffect,
  VideoAsset,
} from '@designage/gql';
import {
  AssetItemIsImage,
  AssetItemIsInteractiveTargetVideo,
  AssetItemIsVideo,
  AssetItemToImageOrVideo,
  getCopyName,
  getDateTime,
  getISOTime,
  getLocalizedTime,
  getUidString,
  isIsoDateTime,
  isIsoTime,
  mediaToAssetItem,
  playlistItemDefaultDuration,
} from '@desquare/utils';
import { cloneDeep } from 'lodash';
import moment from 'moment';

//#region GENERICS

function setPlaylistValue<K extends keyof Playlist>(
  playlist: Playlist,
  key: K,
  value: Playlist[K],
) {
  playlist[key] = value;
}
function setPlaylistScheduleValue<K extends keyof PlaylistSchedule>(
  playlist: Playlist,
  key: K,
  value: PlaylistSchedule[K],
) {
  switch (key) {
    case 'startDate':
      if (!!value && typeof value === 'string') {
        if (!isIsoDateTime(value as string)) {
          console.error(`Cannot set ${key} to ${value}`);
          return;
        }
      }
      playlist.schedule = {
        ...playlist.schedule,
        startDate: value,
      };

      break;
    case 'endDate':
      if (!!value && typeof value === 'string') {
        if (!isIsoDateTime(value as string)) {
          console.error(`Cannot set ${key} to ${value}`);
          return;
        }
      }
      playlist.schedule = {
        ...playlist.schedule,
        endDate: value,
      };
      break;
    case 'rules':
      playlist.schedule = {
        ...playlist.schedule,
        rules: value,
      };
      break;
    default:
      // make sure schedule is at least {}
      playlist.schedule = {
        ...playlist.schedule,
      };
      playlist.schedule![key] = value;
  }
}

function setAssetValue<K extends keyof Asset>(
  asset: Asset,
  key: K,
  value: Asset[K],
) {
  switch (key) {
    // case 'startTime':
    // case 'endTime':
    //   console.info('cannot value obsolete field startTime/endTime');
    //   return;
    case 'actualStartTime':
      if (!isIsoTime(value as string)) {
        console.error(`Cannot set ${key} to ${value}`);
        return;
      }
      break;
    default:
  }
  asset[key] = value;
}

function setItemValue<K extends keyof AssetItem>(
  item: AssetItem,
  key: K,
  value: AssetItem[K],
) {
  switch (key) {
    case 'campaignEnd':
      if (!!value && !isIsoDateTime(value as string)) {
        console.error(`Cannot set ${key} to ${value}`);
        return;
      }
      break;
    case 'campaignStart':
      if (!!value && !isIsoDateTime(value as string)) {
        console.error(`Cannot set ${key} to ${value}`);
        return;
      }
      break;
    default:
  }

  item[key] = value;
}

//#endregion

//#region PLAYLIST ASSET ITEM

function addAssetItem(
  p: Playlist,
  assetIdx: number,
  itemIdx: number = -1,
  newItem: AssetItem,
) {
  if (newItem) {
    if (itemIdx >= 0) {
      p.assets[assetIdx].content.splice(itemIdx, 0, newItem);
    } else {
      p.assets[assetIdx].content.push(newItem);
    }
  }
}

function addMediaItem(
  p: Playlist,
  assetIdx: number,
  itemIdx: number,
  mediaWithMetadata: Media,
) {
  const newItem = mediaToAssetItem(mediaWithMetadata);
  if (newItem) {
    addAssetItem(p, assetIdx, itemIdx, newItem);
  }
}
//#region interactive playlists, virtual items

function getInteractivePageBgItem(p: Playlist, assetIdx: number) {
  for (const item of p.assets[assetIdx].content) {
    if (AssetItemIsImage(item)) {
      const imageAsset = item as ImageAsset;
      if (
        imageAsset.interactiveRegions &&
        imageAsset.interactiveRegions.length > 0
      ) {
        return imageAsset;
      }
    }
    if (AssetItemIsVideo(item)) {
      const videoAsset = item as VideoAsset;
      if (
        videoAsset.interactiveRegions &&
        videoAsset.interactiveRegions.length > 0
      ) {
        return videoAsset;
      }
    }
  }
  return undefined;
}

/** when a virtual asset item is substituted or modified its new contentId is synced with its interactive action (to be reevaluated in the future) */
function reLinkActionToTarget(
  p: Playlist,
  assetIdx: number,
  oldContentId: Maybe<string>,
  newContentId: Maybe<string>,
) {
  const interactivePage = getInteractivePageBgItem(p, assetIdx);
  const touchRegions = interactivePage?.interactiveRegions;
  touchRegions?.forEach((region) => {
    if (region?.action?.target && region?.action?.target === oldContentId)
      [(region.action.target = newContentId)];
  });
}
//#endregion

/** substitute media */
function replaceMedia(
  p: Playlist,
  assetIdx: number,
  itemIdx: number,
  mediaWithMetadata: Media,
) {
  if (!mediaWithMetadata.metadata?.width) return;

  const updateMedia = (
    assetItem: ImageAsset | VideoAsset,
    mediaWithMetadata: Media,
  ) => {
    assetItem.id = getUidString();
    assetItem.contentId = mediaWithMetadata.id;
    assetItem.publicId = mediaWithMetadata.publicId;
    assetItem.height = mediaWithMetadata.metadata?.height;
    assetItem.width = mediaWithMetadata.metadata?.width;
    assetItem.uri = mediaWithMetadata.secureUrl;
  };

  const item = p.assets[assetIdx].content[itemIdx];

  switch (item.type) {
    case AssetType.Image:
      const editingImg = item as ImageAsset;
      updateMedia(editingImg, mediaWithMetadata);
      // TODO: complete me
      break;
    case AssetType.Video:
      const editingVideo = item as VideoAsset;
      const oldContent = cloneDeep(editingVideo);
      updateMedia(editingVideo, mediaWithMetadata);
      editingVideo.duration =
        mediaWithMetadata.metadata.duration || playlistItemDefaultDuration;

      if (AssetItemIsInteractiveTargetVideo(oldContent)) {
        reLinkActionToTarget(
          p,
          assetIdx,
          oldContent.contentId,
          editingVideo.contentId,
        );
      }
      // TODO: complete me
      break;
    default:
    // do nothing
  }
}

function replaceAssetItem(
  p: Playlist,
  assetIdx: number,
  itemIdx: number,
  newContent: AssetItem,
) {
  const oldContent = p.assets[assetIdx].content[itemIdx];
  const mergedContent = { ...oldContent, ...newContent };
  mergedContent.id = getUidString();
  p.assets[assetIdx].content[itemIdx] = mergedContent;
}
function deleteAssetItem(p: Playlist, assetIdx: number, itemIdx: number) {
  p.assets[assetIdx].content.splice(itemIdx, 1);
}

function duplicateItem(p: Playlist, assetIdx: number, itemIdx: number) {
  const newItem = cloneDeep(p.assets[assetIdx].content[itemIdx]);
  newItem.id = getUidString();
  newItem.name = getCopyName(
    newItem.name,
    p.assets[assetIdx].content.map((x) => x.name),
  );
  addAssetItem(p, assetIdx, itemIdx + 1, newItem);
}

function setItemDays(
  p: Playlist,
  assetIdx: number,
  itemIdx: number,
  days: ScheduleDays,
) {
  p.assets[assetIdx].content[itemIdx].days = days;
}
/** enable/disable media item */
function setItemEnabled(
  p: Playlist,
  assetIdx: number,
  itemIdx: number,
  enabled: boolean,
) {
  setItemValue(p.assets[assetIdx].content[itemIdx], 'disabled', !enabled);
}

function setItemDisabledOptimization(
  p: Playlist,
  assetIdx: number,
  itemIdx: number,
  disabled: boolean,
) {
  const imgOrVideo = AssetItemToImageOrVideo(
    p.assets[assetIdx].content[itemIdx],
  );
  if (imgOrVideo) {
    imgOrVideo.disableOptimization = disabled;
  }
}

function setItemResizeCrop(
  p: Playlist,
  assetIdx: number,
  itemIdx: number,
  resizeCropMethod: Maybe<ResizeCropMethod>,
) {
  const item = p.assets[assetIdx].content[itemIdx];
  if (item.type === AssetType.Image) {
    const img = item as ImageAsset;
    img.resizeCropMethod = resizeCropMethod;
  }
  if (item.type === AssetType.Video) {
    const vid = item as VideoAsset;
    vid.resizeCropMethod = resizeCropMethod;
  }
}

function setItemTransitionEffect(
  p: Playlist,
  assetIdx: number,
  itemIdx: number,
  transitionEffect: Maybe<TransitionEffect>,
) {
  const item = p.assets[assetIdx].content[itemIdx];
  if (item.type === AssetType.Image) {
    const img = item as ImageAsset;
    img.transitionEffect = transitionEffect;
  }
}

function setItemPopEnabled(
  p: Playlist,
  assetIdx: number,
  itemIdx: number,
  popEnabled: boolean | 'toggle',
) {
  const item = p.assets[assetIdx].content[itemIdx];
  if (item.type === AssetType.Image) {
    const img = item as ImageAsset;
    const currentValue = img.popEnabled === true;
    const newValue = popEnabled === 'toggle' ? !currentValue : popEnabled;
    img.popEnabled = newValue;
  } else if (item.type === AssetType.Video) {
    const vid = item as VideoAsset;
    const toggle = vid.popEnabled !== true;
    const newValue = popEnabled === 'toggle' ? toggle : popEnabled;
    vid.popEnabled = newValue;
  }
}

function setItemPopTrackId(
  p: Playlist,
  assetIdx: number,
  itemIdx: number,
  popTrackId: string,
) {
  const item = p.assets[assetIdx].content[itemIdx];
  if (item.type === AssetType.Image) {
    const img = item as ImageAsset;
    img.popTrackId = popTrackId;
  } else if (item.type === AssetType.Video) {
    const vid = item as VideoAsset;
    vid.popTrackId = popTrackId;
  }
}

//#endregion

//#region SEQUENCE / DAY PART / ASSET
function getLastAsset(p: Playlist) {
  if (p.assets.length > 0) {
    return p.assets[p.assets.length - 1];
  }
  return undefined;
}

function addAsset(p: Playlist) {
  const allNames = p.assets.map((x) => x.name) || [];
  const name = getCopyName('New Day part', allNames);

  const newAsset: Asset = {
    id: getUidString(),
    name,
    content: [],
  };

  const lastAsset = getLastAsset(p);

  if (lastAsset) {
    const previousStartTime = lastAsset.actualStartTime || '00:00:00';
    const newTime = moment(getDateTime(previousStartTime)).add(1, 'hour');
    newAsset.actualStartTime = getISOTime(newTime);
    newAsset.actualStartTime = `${getLocalizedTime(newTime)}:00`; // returns only HH:MM,
  } else {
    const newTime = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    newAsset.actualStartTime = `${getLocalizedTime(newTime)}:00`;
  }

  p.assets.push(newAsset);

  updateAssetsEndTime(p);
}
function deleteAsset(p: Playlist, assetIdx: number) {
  p.assets.splice(assetIdx, 1);
  updateAssetsEndTime(p);
}
function setAssetStartTime(
  p: Playlist,
  assetIdx: number,
  startTime: string = '00:00:00',
) {
  setAssetValue(p.assets[assetIdx], 'actualStartTime', startTime);
  updateAssetsEndTime(p);
}
function sortDayParts(assets: Asset[]) {
  return assets.sort((a, b) => {
    const aTime = a.actualStartTime || '00:00:00';
    const bTime = b.actualStartTime || '00:00:00';
    return aTime >= bTime ? 1 : -1;
  });
}
function updateAssetsEndTime(p: Playlist) {
  if (p.assets.length > 1) {
    // based from https://gist.github.com/onildoaguiar/6cf7dbf9e0f0b8684eb5b0977b40c5ad#gistcomment-3061281
    // sorts the assets by startTime, sets date to 0 to compare time in the same day
    const sortedAssets = sortDayParts(p.assets);

    // sets the end time of the asset with the start time of the next asset in the array
    const updatedAssets = sortedAssets.map((asset, index, array) => {
      const nextIdx = (index + 1) % sortedAssets.length;
      if (asset.actualStartTime) {
        asset.actualEndTime = array[nextIdx]
          ? array[nextIdx].actualStartTime
          : '00:00:00';
      }
      // sets the end time of the last asset with the start time of the first asset
      return asset;
    });

    p.assets = [...updatedAssets];
  } else {
    const singleSequence = p.assets[0];
    singleSequence.actualEndTime = singleSequence.actualStartTime;
    p.assets = [singleSequence];
  }
}

/** parse interactive regions, add target items to this sequence, remove target items not mapped in regions */
async function setInteractiveItem(
  p: Playlist,
  assetIdx: number,
  interactiveBgItemIdx: number,
  interactiveSource: string,
  interactiveRegions: Maybe<SmilRegion>[],
  width: number,
  height: number,
  targetMediaWithMetadata: Media[],
) {
  const asset = p.assets[assetIdx];
  const bgInteractiveItem = asset.content[interactiveBgItemIdx];
  const imageOrVideoItem = AssetItemToImageOrVideo(bgInteractiveItem);

  if (imageOrVideoItem) {
    imageOrVideoItem.width = width;
    imageOrVideoItem.height = height;
    imageOrVideoItem.interactiveLayoutSource = interactiveSource;
    imageOrVideoItem.interactiveRegions = interactiveRegions;
  }

  if (asset.content) {
    // remove target videos no longer referenced by touch regions
    for (let i = asset.content.length - 1; i >= 0; i--) {
      const assetItem = asset.content[i];

      if (AssetItemIsInteractiveTargetVideo(assetItem)) {
        const launchingTouchRegion = interactiveRegions.find(
          (region) =>
            region?.action?.id === assetItem.id &&
            region?.action?.target === assetItem.contentId,
        );
        if (!launchingTouchRegion) {
          // touch region has been removed, or target video/ action has been changed
          // let's remove it!
          deleteAssetItem(p, assetIdx, i);
        }
      }
    }

    // add target videos not yet present

    for (const region of interactiveRegions) {
      if (region?.action?.id && region.action.target) {
        const itemIdx = asset.content.findIndex(
          (item) =>
            AssetItemIsInteractiveTargetVideo(item) &&
            item.id === region?.action?.id,
        );
        if (itemIdx < 0) {
          // add virtual item
          const videoId = region.action.target;
          const video = targetMediaWithMetadata.find((x) => x.id === videoId); // await this.mediaService.getMediaById(videoId);
          if (!video) continue;

          const assetItem = mediaToAssetItem(video) as VideoAsset;

          assetItem.id = region.action.id;

          assetItem.interactiveLayoutSource = 'target';
          addAssetItem(p, assetIdx, undefined, assetItem);
        }
      }
    }
  }
}

//#endregion

//#region PLAYLIST

const createEmptyPlaylist = (
  name = 'New playlist',
  type = PlaylistType.Scheduled,
) => {
  const p: Playlist = {
    profileId: '', // will be fixed on api side with headers value
    schedule: {},
    assets: [],
    channelGroups: [],
    channels: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    id: '',
    name,
    source: PlaylistSource.Cms,
    status: PlaylistStatus.Draft,
    locations: [],
    type,
    user: null,
  };
  return p;
};

function setPlaylistName(p: Playlist, name: string) {
  // p.name = name;
  setPlaylistValue(p, 'name', name);
}

function getAssetItemsCount(p: Playlist) {
  let count = 0;
  for (const a of p.assets) {
    count = count + a.content.length;
  }
  return count;
}

function isValid(playlist: Playlist) {
  const validFields = {
    valid: true,
    name: { valid: true, inFormMessage: '', submitMessage: '' },
    sequenceLength: { valid: true, inFormMessage: '', submitMessage: '' },
    assetContentDuration: { valid: true, inFormMessage: '', submitMessage: '' },
    assetContentCampaignStart: {
      valid: true,
      inFormMessage: '',
      submitMessage: '',
    },
    assetContentCampaignEnd: {
      valid: true,
      inFormMessage: '',
      submitMessage: '',
    },
  };
  if (!playlist.name || playlist.name === '') {
    validFields.name.valid = false;
    validFields.name.inFormMessage = 'Playlist name is required';
    validFields.name.submitMessage = 'Playlist name is required';
  }

  if (getAssetItemsCount(playlist) === 0) {
    validFields.sequenceLength.valid = false;
    validFields.sequenceLength.inFormMessage = 'Playlist has no Day Parts';
    validFields.sequenceLength.submitMessage = 'Playlist has no Day Parts';
  }

  playlist.assets.find((asset) => {
    asset.content.find((content) => {
      const assetContentDuration =
        'duration' in content ? content.duration === 0 : null;
      if (assetContentDuration) {
        validFields.assetContentDuration.valid = false;
        validFields.assetContentDuration.inFormMessage = `duration cannot be 0 seconds`;
        validFields.assetContentDuration.submitMessage = `${content.name} duration cannot be 0 seconds`;
      }
    });
  });

  playlist.assets.find((asset) =>
    asset.content.find((content) => {
      const assetContentCampaignStart =
        !moment(content.campaignStart).isValid() && !!content.campaignStart;
      if (assetContentCampaignStart) {
        validFields.assetContentCampaignStart.valid = false;
        validFields.assetContentCampaignStart.inFormMessage = `start is invalid`;
        validFields.assetContentCampaignStart.submitMessage = `${content.name} start is invalid`;
      }
    }),
  );

  playlist.assets.find((asset) =>
    asset.content.find((content) => {
      const assetContentCampaignEnd =
        !moment(content.campaignEnd).isValid() && !!content.campaignEnd;
      if (assetContentCampaignEnd) {
        validFields.assetContentCampaignEnd.valid = false;
        validFields.assetContentCampaignEnd.inFormMessage = `end is invalid`;
        validFields.assetContentCampaignEnd.submitMessage = `${content.name} end is invalid`;
      }
    }),
  );
  validFields.valid =
    validFields.name.valid &&
    validFields.sequenceLength.valid &&
    validFields.assetContentDuration.valid &&
    validFields.assetContentCampaignStart.valid &&
    validFields.assetContentCampaignEnd.valid;

  return validFields;
}

//#endregion

export const playlistEngine = {
  isValid,

  generic: {
    setPlaylistValue,
    setAssetValue,
    setItemValue,
  },
  playlist: {
    setValue: setPlaylistValue,
    setScheduleValue: setPlaylistScheduleValue,
    createNew: createEmptyPlaylist,
    setName: setPlaylistName,
  },
  /** sequences/day parts */
  assets: {
    setValue: setAssetValue,
    add: addAsset,
    delete: deleteAsset,
    updateAssetsEndTime,
    getLastAsset,
    setStartTime: setAssetStartTime,
    sortDayParts,
  },
  /** asset items */
  items: {
    setValue: setItemValue,
    addMediaItem,
    addAssetItem,
    duplicateItem,
    deleteAssetItem,
    setItemEnabled,
    setItemDays,
    replaceAssetItem,
    replaceMedia,
    setInteractiveItem,
    setItemResizeCrop,
    setItemTransitionEffect,
    setItemDisabledOptimization,
    setItemPopEnabled,
    setItemPopTrackId,
  },
};
