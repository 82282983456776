import { IScheduleDays } from '@desquare/interfaces';

export interface ScheduleDisplay {
  days: IScheduleDays;
  off: string;
  on: string;
}

export const weekdaysSortOrder = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
};
