import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Playlist, PlaylistForPlaylistListFragment } from '@designage/gql';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { TranslatePipe } from '@ngx-translate/core';
import { Maybe } from 'graphql/jsutils/Maybe';

@Component({
  standalone: true,
  imports: [TranslatePipe, ConfirmDialogComponent],
  selector: 'app-delete-playlist-dialog',
  template: `<app-confirm-dialog
    [useDangerButton]="true"
    [closeButtonEnabled]="true"
    headerText="DELETE_PLAYLIST"
    closeButtonText="DELETE"
    dismissButtonText="CANCEL"
  >
    <div class="card mb-3">
      <div class="card-body border border-danger rounded">
        <div class="overflow-auto limit">
          <ul class="list-group list-group-flush my-3">
            @if (!isDeleteAllSelected) {
              <p>
                {{ 'DELETE_PLAYLIST_PROMPT' | translate }}
              </p>
            } @else {
              <p>
                {{ 'DELETE_PLAYLIST_PROMPT_SELECTED' | translate }}
              </p>
            }
            @for (playlist of selectedPlaylists; track playlist.id) {
              <li
                class="list-group-item text-white "
                [class.rounded-top]="$first"
                [class.rounded-bottom]="$last"
                [class.border-bottom]="!$last"
              >
                {{ playlist.name }}
              </li>
            }
          </ul>
          @if (activeChannelsName?.length) {
            <hr />
            <ul class="list-group list-group-flush mt-2">
              <p>
                {{ 'PLAYLIST_CHANNEL_REMOVED_PROMPT_DETAILS' | translate }}
              </p>
              @for (channel of activeChannelsName; track channel) {
                <li
                  class="list-group-item bg-dark text-white "
                  [class.rounded-top]="$first"
                  [class.rounded-bottom]="$last"
                  [class.border-bottom]="!$last"
                >
                  {{ channel }}
                </li>
              }
            </ul>
          }
        </div>
      </div>
    </div>
  </app-confirm-dialog> `,
  styleUrls: ['./delete-playlist-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeletePlaylistDialogComponent {
  name?: Maybe<string>;
  activeChannelsName?: string[];
  isDeleteAllSelected?: boolean;
  selectedPlaylists?: (Playlist | PlaylistForPlaylistListFragment)[];
}
